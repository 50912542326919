<template>
  <div
    class="fixed left-0 top-[72px] z-50 w-screen bg-white px-4 pb-40 pt-4 text-neutral-900 lg:top-[114px] lg:px-0 lg:pt-9"
    :class="results && results.hits?.length ? 'h-full' : 'h-auto'"
  >
    <div class="mx-auto h-full max-w-[480px] text-center">
      <div class="mb-6 text-2xl font-medium text-neutral-900 lg:mb-8 lg:text-3xl">
        {{ $t('search.title') }}
      </div>

      <FormSearch
        v-model="searchInput"
        :placeholder="$t('search.placeholder')"
        class="w-full"
        @user-action:search-clear="searchInput = ''"
        @user-action:search="handleEnterSubmit"
        @update:model-value="handleSearch"
      />

      <button
        v-if="searchInput.length > 2"
        class="mb-5 mt-7 inline-flex w-full items-center text-base font-medium"
        @click="handleEnterSubmit"
      >
        <Icon name="search" size="md" class="mr-3" :color="colors.neutral[900]" />
        <span class="text-neutral-900">{{ $t('search.morePlaceholder') }}</span>
        &nbsp;
        <span class="text-neutral-500">{{ searchInput }}</span>
      </button>
      <div
        v-if="results && results?.length"
        class="flex flex-col gap-5 pr-2"
        :class="results && results?.length ? 'h-[calc(100%-120px)] overflow-auto' : 'h-auto'"
      >
        <NuxtLink
          v-for="(item, index) in results"
          :key="index"
          class="inline-flex items-center text-left"
          :to="item.route"
          @click="$emit('user-action:click')"
        >
          <Image
            v-if="item.image"
            class="mr-3 inline-block h-10 min-h-[40px] w-[60px] min-w-[60px] object-cover"
            img-class="w-full h-full object-cover"
            :src="item.image.url"
            :alt="item.image.default_field_metadata[locale].alt"
            :width="item.image.width"
            :height="item.image.height"
          />
          <span :class="$style.title" class="text-base font-medium text-neutral-900" v-html="item._highlightResult.title.value" />
          <span class="ml-[6px] mt-1 inline-block text-xs text-neutral-500">{{ item.type }}</span>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getAlgoliaIndexName } from '~/build/utils'
import colors from '#tailwind-config/theme/colors'

const emit = defineEmits(['user-action:click'])

const searchInput = ref('')
const { locale, t } = useI18n()
const INDEX_NAME = getAlgoliaIndexName(locale.value)
const TOTAL_PAGES = ref()
const results = ref()
const router = useRouter()

async function handleSearch() {
  if (searchInput.value && searchInput.value.length > 2) {
    const { data: algoliaData } = await useAsyncAlgoliaSearch({
      indexName: INDEX_NAME,
      query: searchInput.value
    })
    results.value = algoliaData.value.hits.slice(0, 5)
    TOTAL_PAGES.value = algoliaData.value?.nbPages
  } else {
    results.value = []
  }
}

function handleEnterSubmit() {
  router.replace({
    path: t('search.pageLink'),
    query: { name: searchInput.value }
  })

  emit('user-action:click')
}
</script>

<style lang="postcss" module>
.title em {
  @apply text-neutral-500 not-italic;
}
</style>
