import _DEFAULT from "./typography/DEFAULT.mjs"
import _sm from "./typography/sm.mjs"
import _base from "./typography/base.mjs"
import _lg from "./typography/lg.mjs"
import _xl from "./typography/xl.mjs"
import _2xl from "./typography/2xl.mjs"
import _slate from "./typography/slate.mjs"
import _gray from "./typography/gray.mjs"
import _zinc from "./typography/zinc.mjs"
import _neutral from "./typography/neutral.mjs"
import _stone from "./typography/stone.mjs"
import _red from "./typography/red.mjs"
import _orange from "./typography/orange.mjs"
import _amber from "./typography/amber.mjs"
import _yellow from "./typography/yellow.mjs"
import _lime from "./typography/lime.mjs"
import _green from "./typography/green.mjs"
import _emerald from "./typography/emerald.mjs"
import _teal from "./typography/teal.mjs"
import _cyan from "./typography/cyan.mjs"
import _sky from "./typography/sky.mjs"
import _blue from "./typography/blue.mjs"
import _indigo from "./typography/indigo.mjs"
import _violet from "./typography/violet.mjs"
import _purple from "./typography/purple.mjs"
import _fuchsia from "./typography/fuchsia.mjs"
import _pink from "./typography/pink.mjs"
import _rose from "./typography/rose.mjs"
import _invert from "./typography/invert.mjs"
const config = { "DEFAULT": _DEFAULT, "sm": _sm, "base": _base, "lg": _lg, "xl": _xl, "2xl": _2xl, "slate": _slate, "gray": _gray, "zinc": _zinc, "neutral": _neutral, "stone": _stone, "red": _red, "orange": _orange, "amber": _amber, "yellow": _yellow, "lime": _lime, "green": _green, "emerald": _emerald, "teal": _teal, "cyan": _cyan, "sky": _sky, "blue": _blue, "indigo": _indigo, "violet": _violet, "purple": _purple, "fuchsia": _fuchsia, "pink": _pink, "rose": _rose, "invert": _invert }
export { config as default, _DEFAULT, _sm, _base, _lg, _xl, _2xl, _slate, _gray, _zinc, _neutral, _stone, _red, _orange, _amber, _yellow, _lime, _green, _emerald, _teal, _cyan, _sky, _blue, _indigo, _violet, _purple, _fuchsia, _pink, _rose, _invert }