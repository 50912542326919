import _0 from "./zIndex/0.mjs"
import _1 from "./zIndex/1.mjs"
import _2 from "./zIndex/2.mjs"
import _10 from "./zIndex/10.mjs"
import _20 from "./zIndex/20.mjs"
import _30 from "./zIndex/30.mjs"
import _40 from "./zIndex/40.mjs"
import _50 from "./zIndex/50.mjs"
import _auto from "./zIndex/auto.mjs"
const config = { "0": _0, "1": _1, "2": _2, "10": _10, "20": _20, "30": _30, "40": _40, "50": _50, "auto": _auto }
export { config as default, _0, _1, _2, _10, _20, _30, _40, _50, _auto }