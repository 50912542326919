import _transparent from "./outlineColor/transparent.mjs"
import _current from "./outlineColor/current.mjs"
import _gray from "./outlineColor/gray.mjs"
import _black from "./outlineColor/black.mjs"
import _white from "./outlineColor/white.mjs"
import _neutral from "./outlineColor/neutral.mjs"
import _yellow from "./outlineColor/yellow.mjs"
import _blue from "./outlineColor/blue.mjs"
import _green from "./outlineColor/green.mjs"
import _red from "./outlineColor/red.mjs"
import _primary from "./outlineColor/primary.mjs"
import _secondary from "./outlineColor/secondary.mjs"
import _tertiary from "./outlineColor/tertiary.mjs"
import _disabled from "./outlineColor/disabled.mjs"
import _dark from "./outlineColor/dark.mjs"
const config = { "transparent": _transparent, "current": _current, "gray": _gray, "black": _black, "white": _white, "neutral": _neutral, "yellow": _yellow, "blue": _blue, "green": _green, "red": _red, "primary": _primary, "secondary": _secondary, "tertiary": _tertiary, "disabled": _disabled, "dark": _dark }
export { config as default, _transparent, _current, _gray, _black, _white, _neutral, _yellow, _blue, _green, _red, _primary, _secondary, _tertiary, _disabled, _dark }