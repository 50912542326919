export default [
  "3rem",
  {
    "lineHeight": "4.25rem",
    "fontWeight": "500"
  },
  "3rem",
  {
    "lineHeight": "4.25rem",
    "fontWeight": "500"
  },
  "3rem",
  {
    "lineHeight": "4.25rem",
    "fontWeight": "500"
  },
  "3rem",
  {
    "lineHeight": "4.25rem",
    "fontWeight": "500"
  }
]