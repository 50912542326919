<template>
  <nav class="mb-12 mt-4 flex flex-col justify-between gap-x-8 md:flex-row lg:my-0">
    <SiteMapItem
      v-for="item in parents"
      :key="item.id"
      :data="item"
      class="col-span-6 md:col-span-6"
    />
  </nav>
</template>

<script setup lang="ts">
import { MenuRecord } from '~/types/datocms'

const props = defineProps({
  data: {
    type: Array as PropType<MenuRecord[]>,
    default: () => []
  }
})

// Filter top level items
const parents = computed(() => props.data.filter((item) => item.parent === null))
</script>
