export default [
  {
    "color": "var(--tw-prose-body)",
    "maxWidth": "65ch",
    "p": {},
    "[class~=\"lead\"]": {
      "color": "var(--tw-prose-lead)"
    },
    "a": {
      "color": "var(--tw-prose-links)",
      "textDecoration": "underline",
      "fontWeight": "500"
    },
    "strong": {
      "color": "var(--tw-prose-bold)",
      "fontWeight": "600"
    },
    "a strong": {
      "color": "inherit"
    },
    "blockquote strong": {
      "color": "inherit"
    },
    "thead th strong": {
      "color": "inherit"
    },
    "ol": {
      "listStyleType": "decimal"
    },
    "ol[type=\"A\"]": {
      "listStyleType": "upper-alpha"
    },
    "ol[type=\"a\"]": {
      "listStyleType": "lower-alpha"
    },
    "ol[type=\"A\" s]": {
      "listStyleType": "upper-alpha"
    },
    "ol[type=\"a\" s]": {
      "listStyleType": "lower-alpha"
    },
    "ol[type=\"I\"]": {
      "listStyleType": "upper-roman"
    },
    "ol[type=\"i\"]": {
      "listStyleType": "lower-roman"
    },
    "ol[type=\"I\" s]": {
      "listStyleType": "upper-roman"
    },
    "ol[type=\"i\" s]": {
      "listStyleType": "lower-roman"
    },
    "ol[type=\"1\"]": {
      "listStyleType": "decimal"
    },
    "ul": {
      "listStyleType": "disc"
    },
    "ol > li::marker": {
      "fontWeight": "400",
      "color": "var(--tw-prose-counters)"
    },
    "ul > li::marker": {
      "color": "var(--tw-prose-bullets)"
    },
    "dt": {
      "color": "var(--tw-prose-headings)",
      "fontWeight": "600"
    },
    "hr": {
      "borderColor": "var(--tw-prose-hr)",
      "borderTopWidth": 1
    },
    "blockquote": {
      "fontWeight": "500",
      "fontStyle": "italic",
      "color": "var(--tw-prose-quotes)",
      "borderInlineStartWidth": "0.25rem",
      "borderInlineStartColor": "var(--tw-prose-quote-borders)",
      "quotes": "\"\\201C\"\"\\201D\"\"\\2018\"\"\\2019\""
    },
    "blockquote p:first-of-type::before": {
      "content": "open-quote"
    },
    "blockquote p:last-of-type::after": {
      "content": "close-quote"
    },
    "h1": {
      "color": "var(--tw-prose-headings)",
      "fontWeight": "800"
    },
    "h1 strong": {
      "fontWeight": "900",
      "color": "inherit"
    },
    "h2": {
      "color": "var(--tw-prose-headings)",
      "fontWeight": "700"
    },
    "h2 strong": {
      "fontWeight": "800",
      "color": "inherit"
    },
    "h3": {
      "color": "var(--tw-prose-headings)",
      "fontWeight": "600"
    },
    "h3 strong": {
      "fontWeight": "700",
      "color": "inherit"
    },
    "h4": {
      "color": "var(--tw-prose-headings)",
      "fontWeight": "600"
    },
    "h4 strong": {
      "fontWeight": "700",
      "color": "inherit"
    },
    "img": {},
    "picture": {
      "display": "block"
    },
    "video": {},
    "kbd": {
      "fontWeight": "500",
      "fontFamily": "inherit",
      "color": "var(--tw-prose-kbd)",
      "boxShadow": "0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%)"
    },
    "code": {
      "color": "var(--tw-prose-code)",
      "fontWeight": "600"
    },
    "code::before": {
      "content": "\"`\""
    },
    "code::after": {
      "content": "\"`\""
    },
    "a code": {
      "color": "inherit"
    },
    "h1 code": {
      "color": "inherit"
    },
    "h2 code": {
      "color": "inherit"
    },
    "h3 code": {
      "color": "inherit"
    },
    "h4 code": {
      "color": "inherit"
    },
    "blockquote code": {
      "color": "inherit"
    },
    "thead th code": {
      "color": "inherit"
    },
    "pre": {
      "color": "var(--tw-prose-pre-code)",
      "backgroundColor": "var(--tw-prose-pre-bg)",
      "overflowX": "auto",
      "fontWeight": "400"
    },
    "pre code": {
      "backgroundColor": "transparent",
      "borderWidth": "0",
      "borderRadius": "0",
      "padding": "0",
      "fontWeight": "inherit",
      "color": "inherit",
      "fontSize": "inherit",
      "fontFamily": "inherit",
      "lineHeight": "inherit"
    },
    "pre code::before": {
      "content": "none"
    },
    "pre code::after": {
      "content": "none"
    },
    "table": {
      "width": "100%",
      "tableLayout": "auto",
      "textAlign": "start",
      "marginTop": "2em",
      "marginBottom": "2em"
    },
    "thead": {
      "borderBottomWidth": "1px",
      "borderBottomColor": "var(--tw-prose-th-borders)"
    },
    "thead th": {
      "color": "var(--tw-prose-headings)",
      "fontWeight": "600",
      "verticalAlign": "bottom"
    },
    "tbody tr": {
      "borderBottomWidth": "1px",
      "borderBottomColor": "var(--tw-prose-td-borders)"
    },
    "tbody tr:last-child": {
      "borderBottomWidth": "0"
    },
    "tbody td": {
      "verticalAlign": "baseline"
    },
    "tfoot": {
      "borderTopWidth": "1px",
      "borderTopColor": "var(--tw-prose-th-borders)"
    },
    "tfoot td": {
      "verticalAlign": "top"
    },
    "figure > *": {},
    "figcaption": {
      "color": "var(--tw-prose-captions)"
    }
  },
  {
    "--tw-prose-body": "#374151",
    "--tw-prose-headings": "#111827",
    "--tw-prose-lead": "#4b5563",
    "--tw-prose-links": "#111827",
    "--tw-prose-bold": "#111827",
    "--tw-prose-counters": "#6b7280",
    "--tw-prose-bullets": "#d1d5db",
    "--tw-prose-hr": "#e5e7eb",
    "--tw-prose-quotes": "#111827",
    "--tw-prose-quote-borders": "#e5e7eb",
    "--tw-prose-captions": "#6b7280",
    "--tw-prose-kbd": "#111827",
    "--tw-prose-kbd-shadows": "17 24 39",
    "--tw-prose-code": "#111827",
    "--tw-prose-pre-code": "#e5e7eb",
    "--tw-prose-pre-bg": "#1f2937",
    "--tw-prose-th-borders": "#d1d5db",
    "--tw-prose-td-borders": "#e5e7eb",
    "--tw-prose-invert-body": "#d1d5db",
    "--tw-prose-invert-headings": "#fff",
    "--tw-prose-invert-lead": "#9ca3af",
    "--tw-prose-invert-links": "#fff",
    "--tw-prose-invert-bold": "#fff",
    "--tw-prose-invert-counters": "#9ca3af",
    "--tw-prose-invert-bullets": "#4b5563",
    "--tw-prose-invert-hr": "#374151",
    "--tw-prose-invert-quotes": "#f3f4f6",
    "--tw-prose-invert-quote-borders": "#374151",
    "--tw-prose-invert-captions": "#9ca3af",
    "--tw-prose-invert-kbd": "#fff",
    "--tw-prose-invert-kbd-shadows": "255 255 255",
    "--tw-prose-invert-code": "#fff",
    "--tw-prose-invert-pre-code": "#d1d5db",
    "--tw-prose-invert-pre-bg": "rgb(0 0 0 / 50%)",
    "--tw-prose-invert-th-borders": "#4b5563",
    "--tw-prose-invert-td-borders": "#374151"
  },
  {
    "fontSize": "1rem",
    "lineHeight": "1.75",
    "p": {
      "marginTop": "1.25em",
      "marginBottom": "1.25em"
    },
    "[class~=\"lead\"]": {
      "fontSize": "1.25em",
      "lineHeight": "1.6",
      "marginTop": "1.2em",
      "marginBottom": "1.2em"
    },
    "blockquote": {
      "marginTop": "1.6em",
      "marginBottom": "1.6em",
      "paddingInlineStart": "1em"
    },
    "h1": {
      "fontSize": "2.25em",
      "marginTop": "0",
      "marginBottom": "0.8888889em",
      "lineHeight": "1.1111111"
    },
    "h2": {
      "fontSize": "1.5em",
      "marginTop": "2em",
      "marginBottom": "1em",
      "lineHeight": "1.3333333"
    },
    "h3": {
      "fontSize": "1.25em",
      "marginTop": "1.6em",
      "marginBottom": "0.6em",
      "lineHeight": "1.6"
    },
    "h4": {
      "marginTop": "1.5em",
      "marginBottom": "0.5em",
      "lineHeight": "1.5"
    },
    "img": {
      "marginTop": "2em",
      "marginBottom": "2em"
    },
    "picture": {
      "marginTop": "2em",
      "marginBottom": "2em"
    },
    "picture > img": {
      "marginTop": "0",
      "marginBottom": "0"
    },
    "video": {
      "marginTop": "2em",
      "marginBottom": "2em"
    },
    "kbd": {
      "fontSize": "0.875em",
      "borderRadius": "0.3125rem",
      "paddingTop": "0.1875em",
      "paddingInlineEnd": "0.375em",
      "paddingBottom": "0.1875em",
      "paddingInlineStart": "0.375em"
    },
    "code": {
      "fontSize": "0.875em"
    },
    "h2 code": {
      "fontSize": "0.875em"
    },
    "h3 code": {
      "fontSize": "0.9em"
    },
    "pre": {
      "fontSize": "0.875em",
      "lineHeight": "1.7142857",
      "marginTop": "1.7142857em",
      "marginBottom": "1.7142857em",
      "borderRadius": "0.375rem",
      "paddingTop": "0.8571429em",
      "paddingInlineEnd": "1.1428571em",
      "paddingBottom": "0.8571429em",
      "paddingInlineStart": "1.1428571em"
    },
    "ol": {
      "marginTop": "1.25em",
      "marginBottom": "1.25em",
      "paddingInlineStart": "1.625em"
    },
    "ul": {
      "marginTop": "1.25em",
      "marginBottom": "1.25em",
      "paddingInlineStart": "1.625em"
    },
    "li": {
      "marginTop": "0.5em",
      "marginBottom": "0.5em"
    },
    "ol > li": {
      "paddingInlineStart": "0.375em"
    },
    "ul > li": {
      "paddingInlineStart": "0.375em"
    },
    "> ul > li p": {
      "marginTop": "0.75em",
      "marginBottom": "0.75em"
    },
    "> ul > li > p:first-child": {
      "marginTop": "1.25em"
    },
    "> ul > li > p:last-child": {
      "marginBottom": "1.25em"
    },
    "> ol > li > p:first-child": {
      "marginTop": "1.25em"
    },
    "> ol > li > p:last-child": {
      "marginBottom": "1.25em"
    },
    "ul ul, ul ol, ol ul, ol ol": {
      "marginTop": "0.75em",
      "marginBottom": "0.75em"
    },
    "dl": {
      "marginTop": "1.25em",
      "marginBottom": "1.25em"
    },
    "dt": {
      "marginTop": "1.25em"
    },
    "dd": {
      "marginTop": "0.5em",
      "paddingInlineStart": "1.625em"
    },
    "hr": {
      "marginTop": "3em",
      "marginBottom": "3em"
    },
    "hr + *": {
      "marginTop": "0"
    },
    "h2 + *": {
      "marginTop": "0"
    },
    "h3 + *": {
      "marginTop": "0"
    },
    "h4 + *": {
      "marginTop": "0"
    },
    "table": {
      "fontSize": "0.875em",
      "lineHeight": "1.7142857"
    },
    "thead th": {
      "paddingInlineEnd": "0.5714286em",
      "paddingBottom": "0.5714286em",
      "paddingInlineStart": "0.5714286em"
    },
    "thead th:first-child": {
      "paddingInlineStart": "0"
    },
    "thead th:last-child": {
      "paddingInlineEnd": "0"
    },
    "tbody td, tfoot td": {
      "paddingTop": "0.5714286em",
      "paddingInlineEnd": "0.5714286em",
      "paddingBottom": "0.5714286em",
      "paddingInlineStart": "0.5714286em"
    },
    "tbody td:first-child, tfoot td:first-child": {
      "paddingInlineStart": "0"
    },
    "tbody td:last-child, tfoot td:last-child": {
      "paddingInlineEnd": "0"
    },
    "figure": {
      "marginTop": "2em",
      "marginBottom": "2em"
    },
    "figure > *": {
      "marginTop": "0",
      "marginBottom": "0"
    },
    "figcaption": {
      "fontSize": "0.875em",
      "lineHeight": "1.4285714",
      "marginTop": "0.8571429em"
    }
  },
  {
    "> :first-child": {
      "marginTop": "0"
    },
    "> :last-child": {
      "marginBottom": "0"
    }
  },
  {
    "--tw-prose-body": "#171717",
    "--tw-prose-headings": "#000000",
    "--tw-prose-lead": "#171717",
    "--tw-prose-links": "#171717",
    "--tw-prose-bold": "#171717",
    "--tw-prose-counters": "#171717",
    "--tw-prose-bullets": "#171717",
    "--tw-prose-hr": "#a3a3a3",
    "--tw-prose-quotes": "#171717",
    "--tw-prose-quote-borders": "transparent",
    "maxWidth": "100ch",
    "fontSize": "1rem",
    "lineHeight": "1.5/* 24px */",
    "fontFamily": "Outfit,ui-sans-serif,system-ui,Outfit,ui-sans-serif,system-ui,Outfit,ui-sans-serif,system-ui,Outfit,ui-sans-serif,system-ui",
    "a": {
      "marginBottom": "1.5rem",
      "textDecoration": "underline",
      "&:hover": {
        "textDecoration": "none"
      }
    },
    "strong": {
      "color": "#000000",
      "fontWeight": 500
    },
    "h1": {
      "fontSize": "2.25rem",
      "fontWeight": 500,
      "marginBottom": "1rem",
      "marginTop": 0
    },
    "h2": {
      "fontSize": "1.875rem",
      "fontWeight": 500,
      "marginBottom": "1.5rem",
      "marginTop": 0
    },
    "h3": {
      "fontSize": "1.5rem",
      "fontWeight": 500,
      "marginBottom": "1.5rem",
      "marginTop": 0
    },
    "h4": {
      "fontSize": "1.125rem",
      "fontWeight": 400,
      "marginBottom": "1.5rem",
      "marginTop": 0
    },
    "h5": {
      "fontSize": "1.125rem",
      "fontWeight": 400,
      "marginBottom": "1.5rem",
      "marginTop": 0
    },
    "h6": {
      "fontSize": "1.125rem",
      "fontWeight": 400,
      "marginBottom": "1.5rem",
      "marginTop": 0
    },
    "p": {
      "marginTop": 0,
      "marginBottom": "1.5rem"
    },
    "ul > li::marker": {
      "color": "var(--tw-prose-bullets)",
      "fontSize": "12px"
    },
    "ul > li > p": {
      "marginTop": "0px",
      "marginBottom": "0px"
    },
    "li": {
      "marginTop": "0px",
      "marginBottom": "0px"
    },
    "blockquote": {
      "fontSize": "1.5rem",
      "marginTop": "2rem",
      "marginBottom": "2rem",
      "paddingLeft": "0px"
    },
    "hr": {
      "marginTop": 0,
      "marginBottom": "1.5rem"
    },
    "img": {
      "marginTop": 0,
      "marginBottom": 0
    }
  }
]