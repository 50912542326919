export default [
  "0.875rem",
  {
    "lineHeight": "1.25rem"
  },
  "0.875rem",
  {
    "lineHeight": "1.25rem"
  },
  "0.875rem",
  {
    "lineHeight": "1.25rem"
  },
  "0.875rem",
  {
    "lineHeight": "1.25rem"
  }
]