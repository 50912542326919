<template>
  <div
    class="group relative mb-6 w-full max-w-lg lg:mx-3 lg:w-fit"
    @mouseenter="submenuShow"
    @mouseleave="submenuHide"
  >
    <component
      :is="data.reference !== null ? NuxtLink : data.externalLink ? NuxtLink : 'button'"
      :to="
        data?.reference
          ? localePathByType(data.reference._modelApiKey as string, data.reference.slug as string)
          : data?.externalLink
      "
      class="relative my-2 inline-flex items-center gap-3 font-medium transition-colors text-base lg:text-sm xl:text-base"
      :target="data.externalLink ? '_blank' : undefined"
      :class="[
        isCurrentRoute && '!text-primary',
        isLink ? 'cursor-pointer' : 'cursor-auto',
        hasDropdown ? 'cursor-pointer' : 'cursor-auto',
        isWhite && 'lg:text-white'
      ]"
      @click="onClick(data)"
    >
      {{ data.title }}
      <Icon
        v-if="hasDropdown"
        name="chevron-down"
        size="xs"
        :color="isSubmenuVisible || !isWhite ? theme.colors?.primary : 'inherit'"
        :class="[isSubmenuVisible ? 'rotate-180' : 'rotate-0']"
        class="pointer-events-none transition-transform duration-500"
      />
    </component>
    <Transition name="fade">
      <div
        v-show="isSubmenuVisible"
        class="relative w-full z-20 lg:absolute lg:-left-6 lg:top-full lg:shadow-2xl"
        :class="[idx === 0 ? 'lg:w-[968px]' : 'lg:w-[624px]']"
      >
        <div :class="{ 'lg:flex': idx === 0 }">
          <ul
            v-if="hasDropdown"
            class="grid grid-cols-6 lg:mb-0 lg:grid-cols-12 lg:gap-8 lg:bg-white lg:p-8"
            :class="{ 'lg:flex-[624px]': idx === 0 }"
          >
            <li v-for="item in childrenFiltered" :key="item.id" class="col-span-6">
              <NuxtLink
                :to="
                  item.reference
                    ? localePathByType(
                        item.reference._modelApiKey as string,
                        item.reference.slug as string
                      )
                    : item.externalLink
                "
                :target="item.externalLink ? '_blank' : undefined"
                class="block py-2"
                :class="{
                  ' text-primary':
                    item.reference !== null && $route.params.slug === item.reference.slug
                }"
                @click="onClickChild"
              >
                <div class="text-base transition-colors">
                  {{ item.title }}
                </div>
                <div v-if="item.description" class="text-sm">{{ item.description }}</div>
              </NuxtLink>
            </li>
          </ul>
          <div
            v-if="Array.isArray(data.blocks) && data.blocks.length"
            :class="idx === 0 ? 'lg:flex-[344px]' : 'w-full bg-white lg:px-8 lg:pb-8'"
          >
            <div
              class="bg-white px-4 pb-2"
              :class="idx === 0 ? 'h-full pt-8' : 'pt-8 lg:grid lg:grid-cols-2 lg:pt-2'"
            >
              <div
                v-if="data.blocks[0].title"
                class="mb-6 px-3 text-base"
                :class="idx === 0 ? 'mb-6' : 'flex flex-col justify-center lg:mb-0'"
              >
                {{ data.blocks[0].title }}
              </div>
              <ul v-if="Array.isArray(data.blocks[0].reference) && data.blocks[0].reference.length">
                <li
                  v-for="item in data.blocks[0].reference"
                  :key="item.title"
                  class="col-span-6 px-3 pb-8"
                >
                  <NuxtLink
                    :to="
                      localePathByType(
                        item.reference._modelApiKey as string,
                        item.reference.slug as string
                      )
                    "
                    class="border-gray1 block border-b-2 transition-colors hover:border-primary hover:text-primary"
                    :class="{
                      'border-primary': item.reference !== null && $route.params.slug === item.slug
                    }"
                    @click="onClickChild"
                  >
                    <div class="mb-2 text-base">{{ item.title }}</div>
                  </NuxtLink>
                </li>
              </ul>

              <Button
                v-else-if="Array.isArray(data.blocks[0].buttons) && data.blocks[0].buttons.length"
                :label="data.blocks[0].buttons[0].title"
                :to="
                  data.blocks[0].buttons[0].pageLink !== null
                    ? localePathByType(
                        data.blocks[0].buttons[0].pageLink.__typename as string,
                        data.blocks[0].buttons[0].pageLink.slug as string
                      )
                    : data.blocks[0].buttons[0].externalLink
                "
                :target="data.blocks[0].buttons[0].externalLink ? '_blank' : undefined"
                variant="link"
                :icon="{
                  name: 'square-arrow-right',
                  color: theme.colors.primary
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { theme } from '#tailwind-config'

const NuxtLink = resolveComponent('NuxtLink')
const { isSubmenuVisible, menuHide, submenuShow, submenuHide, submenuToggle } = useMenu()
const { localePathByType } = useLocalePathByType()
const route = useRoute()

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  idx: {
    type: Number
  },
  isWhite: {
    type: Boolean,
    default: false
  }
})

const isCurrentRoute = computed(
  () => props.data.reference && route.params.slug === props.data.reference.slug
)
const isLink = computed(() => props.data.reference ?? props.data.externalLink)
const hasDropdown = computed(() => props.data.children.length)

// Extract only published translations
const childrenFiltered = computed(() =>
  props.data.children.filter((x) => x.publishTranslation === true)
)

function onClick(data: object) {
  if (Array.isArray(data.children) && data.children.length) {
    submenuToggle()
  } else {
    menuHide()
  }
}

function onClickChild() {
  submenuHide()
  menuHide()
}
</script>

<style lang="postcss">
.menu-enter-active {
  transition: transform 400ms var(--ease-out-back);
}

.menu-enter-from,
.menu-leave-to {
  transform: translateX(-10px);
}
</style>
