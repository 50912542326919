export default [
  "3.75rem",
  {
    "lineHeight": "4.5rem",
    "fontWeight": "500"
  },
  "3.75rem",
  {
    "lineHeight": "4.5rem",
    "fontWeight": "500"
  },
  "3.75rem",
  {
    "lineHeight": "4.5rem",
    "fontWeight": "500"
  },
  "3.75rem",
  {
    "lineHeight": "4.5rem",
    "fontWeight": "500"
  }
]