<template>
  <div>
    <component
      :is="data.reference !== null ? NuxtLink : data.externalLink ? NuxtLink : 'span'"
      :to="
        data.reference
          ? localePathByType(data.reference._modelApiKey, data.reference.slug!)
          : data.externalLink
            ? data.externalLink
            : undefined
      "
      :target="data.externalLink ? '_blank' : undefined"
      class="my-6 block font-semibold lg:mb-6 lg:mt-0"
    >
      {{ data.title }}
    </component>
    <ul v-if="Array.isArray(data.children) && data.children.length" class="text-sm">
      <li v-for="item in childrenFiltered" :key="item.id">
        <NuxtLink
          :to="
            item!.reference
              ? localePathByType(item!.reference._modelApiKey, item!.reference.slug!)
              : item!.externalLink
          "
          class="my-4 block w-fit"
          :target="data.externalLink ? '_blank' : undefined"
        >
          {{ item.title }}
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type { MenuRecord } from '~/types/datocms'

const NuxtLink = resolveComponent('NuxtLink')
const { localePathByType } = useLocalePathByType()

const props = defineProps({
  data: {
    type: Object as PropType<MenuRecord>,
    default: () => {}
  }
})

// Extract only published translations
const childrenFiltered = computed(() =>
  props.data.children?.filter((x) => x!.publishTranslation === true)
)
</script>
