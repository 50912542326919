import _0 from "./transitionDelay/0.mjs"
import _75 from "./transitionDelay/75.mjs"
import _100 from "./transitionDelay/100.mjs"
import _150 from "./transitionDelay/150.mjs"
import _200 from "./transitionDelay/200.mjs"
import _300 from "./transitionDelay/300.mjs"
import _400 from "./transitionDelay/400.mjs"
import _500 from "./transitionDelay/500.mjs"
import _600 from "./transitionDelay/600.mjs"
import _700 from "./transitionDelay/700.mjs"
import _800 from "./transitionDelay/800.mjs"
import _1000 from "./transitionDelay/1000.mjs"
const config = { "0": _0, "75": _75, "100": _100, "150": _150, "200": _200, "300": _300, "400": _400, "500": _500, "600": _600, "700": _700, "800": _800, "1000": _1000 }
export { config as default, _0, _75, _100, _150, _200, _300, _400, _500, _600, _700, _800, _1000 }