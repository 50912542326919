<template>
  <div>
    <div
      ref="dropdown"
      class="relative z-10 flex h-[50px] w-[50px] items-center justify-center rounded-full text-white lg:border"
      :class="isWhite ? 'text-white lg:border-white' : 'text-neutral-900 lg:border-neutral-900'"
    >
      <button class="absolute h-full w-full" @click="isActive = !isActive">
        <Icon
          class="transition-colors duration-500"
          name="search"
          :color="
            isActive ? theme.colors.red.DEFAULT : isWhite ? 'white' : theme.colors.neutral[900]
          "
        />
      </button>
      <Transition name="fade">
        <SearchDropdown v-if="isActive" @user-action:click="hideDropdown" />
      </Transition>
      <div
        v-if="isActive"
        class="fixed left-0 top-[72px] -z-1 h-screen w-screen bg-neutral-900 bg-opacity-60 lg:top-[114px]"
        @click="hideDropdown"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { theme } from '#tailwind-config'

const { bodyLock, bodyUnlock } = useBodyLock()

defineProps({
  isWhite: {
    type: Boolean,
    default: false
  }
})

const dropdown = ref(null)
onClickOutside(dropdown, () => hideDropdown())

const isActive = ref(false)

function hideDropdown() {
  isActive.value = false
}

watch(isActive, () => {
  if (isActive.value) {
    bodyLock()
  } else {
    bodyUnlock()
  }
})
</script>
