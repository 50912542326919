import _1 from "./gridTemplateColumns/1.mjs"
import _2 from "./gridTemplateColumns/2.mjs"
import _3 from "./gridTemplateColumns/3.mjs"
import _4 from "./gridTemplateColumns/4.mjs"
import _5 from "./gridTemplateColumns/5.mjs"
import _6 from "./gridTemplateColumns/6.mjs"
import _7 from "./gridTemplateColumns/7.mjs"
import _8 from "./gridTemplateColumns/8.mjs"
import _9 from "./gridTemplateColumns/9.mjs"
import _10 from "./gridTemplateColumns/10.mjs"
import _11 from "./gridTemplateColumns/11.mjs"
import _12 from "./gridTemplateColumns/12.mjs"
import _none from "./gridTemplateColumns/none.mjs"
import _subgrid from "./gridTemplateColumns/subgrid.mjs"
const config = { "1": _1, "2": _2, "3": _3, "4": _4, "5": _5, "6": _6, "7": _7, "8": _8, "9": _9, "10": _10, "11": _11, "12": _12, "none": _none, "subgrid": _subgrid }
export { config as default, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _none, _subgrid }