import _transparent from "./divideColor/transparent.mjs"
import _current from "./divideColor/current.mjs"
import _gray from "./divideColor/gray.mjs"
import _black from "./divideColor/black.mjs"
import _white from "./divideColor/white.mjs"
import _neutral from "./divideColor/neutral.mjs"
import _yellow from "./divideColor/yellow.mjs"
import _blue from "./divideColor/blue.mjs"
import _green from "./divideColor/green.mjs"
import _red from "./divideColor/red.mjs"
import _primary from "./divideColor/primary.mjs"
import _secondary from "./divideColor/secondary.mjs"
import _tertiary from "./divideColor/tertiary.mjs"
import _disabled from "./divideColor/disabled.mjs"
import _dark from "./divideColor/dark.mjs"
import _DEFAULT from "./divideColor/DEFAULT.mjs"
const config = { "transparent": _transparent, "current": _current, "gray": _gray, "black": _black, "white": _white, "neutral": _neutral, "yellow": _yellow, "blue": _blue, "green": _green, "red": _red, "primary": _primary, "secondary": _secondary, "tertiary": _tertiary, "disabled": _disabled, "dark": _dark, "DEFAULT": _DEFAULT }
export { config as default, _transparent, _current, _gray, _black, _white, _neutral, _yellow, _blue, _green, _red, _primary, _secondary, _tertiary, _disabled, _dark, _DEFAULT }