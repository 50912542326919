<template>
  <div
    class="fixed left-0 top-0 h-screen w-full overflow-hidden bg-white transition-opacity duration-100 ease-linear"
    :class="isVisible ? 'opacity-100' : 'invisible opacity-0'"
  ></div>
</template>

<script setup lang="ts">
const isVisible = useState('menu')
</script>
