import { breakpointsTailwind } from '@vueuse/core'

export const useMenuVisible = () => useState('menu', () => false)

export default function () {
  const breakpoints = useBreakpoints(breakpointsTailwind)
  const isScreenLg = breakpoints.greater('lg')

  const { bodyLock, bodyUnlock } = useBodyLock()

  // Main Menu
  const isVisible = useState('menu')

  function menuHide() {
    isVisible.value = false
    bodyUnlock()
  }

  function menuShow() {
    isVisible.value = true
    bodyLock()
  }

  function menuToggle() {
    if (!isVisible.value) {
      menuShow()
    } else {
      menuHide()
    }
  }

  // Main Submenu
  const isSubmenuVisible = ref(false)

  function submenuShow() {
    if (isScreenLg.value) {
      isSubmenuVisible.value = true
    }
  }

  function submenuHide() {
    if (isScreenLg.value) {
      isSubmenuVisible.value = false
    }
  }

  function submenuToggle() {
    isSubmenuVisible.value = !isSubmenuVisible.value
  }

  return {
    isSubmenuVisible,
    menuHide,
    menuShow,
    menuToggle,
    submenuShow,
    submenuHide,
    submenuToggle
  }
}
