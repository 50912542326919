import _transparent from "./ringOffsetColor/transparent.mjs"
import _current from "./ringOffsetColor/current.mjs"
import _gray from "./ringOffsetColor/gray.mjs"
import _black from "./ringOffsetColor/black.mjs"
import _white from "./ringOffsetColor/white.mjs"
import _neutral from "./ringOffsetColor/neutral.mjs"
import _yellow from "./ringOffsetColor/yellow.mjs"
import _blue from "./ringOffsetColor/blue.mjs"
import _green from "./ringOffsetColor/green.mjs"
import _red from "./ringOffsetColor/red.mjs"
import _primary from "./ringOffsetColor/primary.mjs"
import _secondary from "./ringOffsetColor/secondary.mjs"
import _tertiary from "./ringOffsetColor/tertiary.mjs"
import _disabled from "./ringOffsetColor/disabled.mjs"
import _dark from "./ringOffsetColor/dark.mjs"
const config = { "transparent": _transparent, "current": _current, "gray": _gray, "black": _black, "white": _white, "neutral": _neutral, "yellow": _yellow, "blue": _blue, "green": _green, "red": _red, "primary": _primary, "secondary": _secondary, "tertiary": _tertiary, "disabled": _disabled, "dark": _dark }
export { config as default, _transparent, _current, _gray, _black, _white, _neutral, _yellow, _blue, _green, _red, _primary, _secondary, _tertiary, _disabled, _dark }