import _0 from "./scale/0.mjs"
import _50 from "./scale/50.mjs"
import _75 from "./scale/75.mjs"
import _90 from "./scale/90.mjs"
import _95 from "./scale/95.mjs"
import _100 from "./scale/100.mjs"
import _105 from "./scale/105.mjs"
import _110 from "./scale/110.mjs"
import _125 from "./scale/125.mjs"
import _150 from "./scale/150.mjs"
const config = { "0": _0, "50": _50, "75": _75, "90": _90, "95": _95, "100": _100, "105": _105, "110": _110, "125": _125, "150": _150 }
export { config as default, _0, _50, _75, _90, _95, _100, _105, _110, _125, _150 }