<template>
  <nav
    v-show="isMenuVisible || isDesktop"
    class="absolute left-0 top-0 h-screen w-full pt-16 lg:left-64 xl:left-72 lg:top-9 lg:h-auto lg:w-fit lg:p-0"
  >
    <div
      class="flex h-full flex-col overflow-scroll px-4 pb-40 lg:h-auto lg:flex-row lg:overflow-visible lg:p-0"
    >
      <MenuListItem
        v-for="(item, idx) in parents"
        :key="idx"
        :idx="idx"
        :data="item"
        :is-white="isWhite"
      />
    </div>
  </nav>
</template>

<script setup lang="ts">
import { MenuRecord } from '~/types/datocms'

const props = defineProps({
  data: {
    type: Array as PropType<MenuRecord[]>,
    default: () => []
  },
  isWhite: {
    type: Boolean,
    default: false
  }
})

const isMenuVisible = useState('menu')
const isDesktop = useMediaQuery('(min-width: 1024px)')

// Filter top level items
const parents = computed(() => props.data.filter((item) => item.parent === null))
</script>
