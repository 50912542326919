import _thin from "./fontWeight/thin.mjs"
import _extralight from "./fontWeight/extralight.mjs"
import _light from "./fontWeight/light.mjs"
import _normal from "./fontWeight/normal.mjs"
import _medium from "./fontWeight/medium.mjs"
import _semibold from "./fontWeight/semibold.mjs"
import _bold from "./fontWeight/bold.mjs"
import _extrabold from "./fontWeight/extrabold.mjs"
import _black from "./fontWeight/black.mjs"
const config = { "thin": _thin, "extralight": _extralight, "light": _light, "normal": _normal, "medium": _medium, "semibold": _semibold, "bold": _bold, "extrabold": _extrabold, "black": _black }
export { config as default, _thin, _extralight, _light, _normal, _medium, _semibold, _bold, _extrabold, _black }