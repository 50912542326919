<template>
  <header
    class="relative z-10 transition-opacity delay-300 duration-1000"
    :class="[
      isHeroMounted && 'border-b border-white border-opacity-10',
      mounted ? 'opacity-1' : 'opacity-0'
    ]"
  >
    <MenuBackDrop />
    <div class="container relative flex items-center justify-between py-5 lg:py-8">
      <Logo :is-white="isHeroMounted" class="h-8 w-36 lg:h-12 lg:w-56" />
      <slot />
      <AppSearch v-if="!isMenuVisible" class="absolute right-14 lg:right-28" :is-white="isHeroMounted" />
      <LanguageSwitcher v-if="mounted" :is-white="isHeroMounted" />
    </div>
    <MenuButton :is-white="isHeroMounted" />
  </header>
</template>

<script setup lang="ts">
const isHeroMounted = useState<boolean>('heroMounted')
const mounted = ref(false)
const isMenuVisible = useState('menu')

onMounted(() => {
  mounted.value = true
})
</script>
