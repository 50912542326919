<template>
  <div>
    <Header>
      <MenuList :data="store.allMenus" :is-white="isHeroMounted" />
    </Header>
    <main>
      <slot />
    </main>
    <Footer :data="store" />
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from '~/stores/app'
import locales from '~/i18n/locales'

const { locale } = useI18n()
const langAttr = computed(() => locales.find((l) => l.code === locale.value).code)
const store = useAppStore()

const isHeroMounted = useState<boolean>('heroMounted')
const mounted = ref(false)

onMounted(() => {
  mounted.value = true
})

useHead({
  htmlAttrs: {
    lang: langAttr
  }
})
</script>
