import _busy from "./aria/busy.mjs"
import _checked from "./aria/checked.mjs"
import _disabled from "./aria/disabled.mjs"
import _expanded from "./aria/expanded.mjs"
import _hidden from "./aria/hidden.mjs"
import _pressed from "./aria/pressed.mjs"
import _readonly from "./aria/readonly.mjs"
import _required from "./aria/required.mjs"
import _selected from "./aria/selected.mjs"
const config = { "busy": _busy, "checked": _checked, "disabled": _disabled, "expanded": _expanded, "hidden": _hidden, "pressed": _pressed, "readonly": _readonly, "required": _required, "selected": _selected }
export { config as default, _busy, _checked, _disabled, _expanded, _hidden, _pressed, _readonly, _required, _selected }