import _0 from "./rotate/0.mjs"
import _1 from "./rotate/1.mjs"
import _2 from "./rotate/2.mjs"
import _3 from "./rotate/3.mjs"
import _6 from "./rotate/6.mjs"
import _12 from "./rotate/12.mjs"
import _45 from "./rotate/45.mjs"
import _90 from "./rotate/90.mjs"
import _180 from "./rotate/180.mjs"
const config = { "0": _0, "1": _1, "2": _2, "3": _3, "6": _6, "12": _12, "45": _45, "90": _90, "180": _180 }
export { config as default, _0, _1, _2, _3, _6, _12, _45, _90, _180 }