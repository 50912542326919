import _transparent from "./accentColor/transparent.mjs"
import _current from "./accentColor/current.mjs"
import _gray from "./accentColor/gray.mjs"
import _black from "./accentColor/black.mjs"
import _white from "./accentColor/white.mjs"
import _neutral from "./accentColor/neutral.mjs"
import _yellow from "./accentColor/yellow.mjs"
import _blue from "./accentColor/blue.mjs"
import _green from "./accentColor/green.mjs"
import _red from "./accentColor/red.mjs"
import _primary from "./accentColor/primary.mjs"
import _secondary from "./accentColor/secondary.mjs"
import _tertiary from "./accentColor/tertiary.mjs"
import _disabled from "./accentColor/disabled.mjs"
import _dark from "./accentColor/dark.mjs"
import _auto from "./accentColor/auto.mjs"
const config = { "transparent": _transparent, "current": _current, "gray": _gray, "black": _black, "white": _white, "neutral": _neutral, "yellow": _yellow, "blue": _blue, "green": _green, "red": _red, "primary": _primary, "secondary": _secondary, "tertiary": _tertiary, "disabled": _disabled, "dark": _dark, "auto": _auto }
export { config as default, _transparent, _current, _gray, _black, _white, _neutral, _yellow, _blue, _green, _red, _primary, _secondary, _tertiary, _disabled, _dark, _auto }