import _none from "./stroke/none.mjs"
import _transparent from "./stroke/transparent.mjs"
import _current from "./stroke/current.mjs"
import _gray from "./stroke/gray.mjs"
import _black from "./stroke/black.mjs"
import _white from "./stroke/white.mjs"
import _neutral from "./stroke/neutral.mjs"
import _yellow from "./stroke/yellow.mjs"
import _blue from "./stroke/blue.mjs"
import _green from "./stroke/green.mjs"
import _red from "./stroke/red.mjs"
import _primary from "./stroke/primary.mjs"
import _secondary from "./stroke/secondary.mjs"
import _tertiary from "./stroke/tertiary.mjs"
import _disabled from "./stroke/disabled.mjs"
import _dark from "./stroke/dark.mjs"
const config = { "none": _none, "transparent": _transparent, "current": _current, "gray": _gray, "black": _black, "white": _white, "neutral": _neutral, "yellow": _yellow, "blue": _blue, "green": _green, "red": _red, "primary": _primary, "secondary": _secondary, "tertiary": _tertiary, "disabled": _disabled, "dark": _dark }
export { config as default, _none, _transparent, _current, _gray, _black, _white, _neutral, _yellow, _blue, _green, _red, _primary, _secondary, _tertiary, _disabled, _dark }