export default [
  {
    "fontSize": "1.125rem",
    "lineHeight": "1.7777778",
    "p": {
      "marginTop": "1.3333333em",
      "marginBottom": "1.3333333em"
    },
    "[class~=\"lead\"]": {
      "fontSize": "1.2222222em",
      "lineHeight": "1.4545455",
      "marginTop": "1.0909091em",
      "marginBottom": "1.0909091em"
    },
    "blockquote": {
      "marginTop": "1.6666667em",
      "marginBottom": "1.6666667em",
      "paddingInlineStart": "1em"
    },
    "h1": {
      "fontSize": "2.6666667em",
      "marginTop": "0",
      "marginBottom": "0.8333333em",
      "lineHeight": "1"
    },
    "h2": {
      "fontSize": "1.6666667em",
      "marginTop": "1.8666667em",
      "marginBottom": "1.0666667em",
      "lineHeight": "1.3333333"
    },
    "h3": {
      "fontSize": "1.3333333em",
      "marginTop": "1.6666667em",
      "marginBottom": "0.6666667em",
      "lineHeight": "1.5"
    },
    "h4": {
      "marginTop": "1.7777778em",
      "marginBottom": "0.4444444em",
      "lineHeight": "1.5555556"
    },
    "img": {
      "marginTop": "1.7777778em",
      "marginBottom": "1.7777778em"
    },
    "picture": {
      "marginTop": "1.7777778em",
      "marginBottom": "1.7777778em"
    },
    "picture > img": {
      "marginTop": "0",
      "marginBottom": "0"
    },
    "video": {
      "marginTop": "1.7777778em",
      "marginBottom": "1.7777778em"
    },
    "kbd": {
      "fontSize": "0.8888889em",
      "borderRadius": "0.3125rem",
      "paddingTop": "0.2222222em",
      "paddingInlineEnd": "0.4444444em",
      "paddingBottom": "0.2222222em",
      "paddingInlineStart": "0.4444444em"
    },
    "code": {
      "fontSize": "0.8888889em"
    },
    "h2 code": {
      "fontSize": "0.8666667em"
    },
    "h3 code": {
      "fontSize": "0.875em"
    },
    "pre": {
      "fontSize": "0.8888889em",
      "lineHeight": "1.75",
      "marginTop": "2em",
      "marginBottom": "2em",
      "borderRadius": "0.375rem",
      "paddingTop": "1em",
      "paddingInlineEnd": "1.5em",
      "paddingBottom": "1em",
      "paddingInlineStart": "1.5em"
    },
    "ol": {
      "marginTop": "1.3333333em",
      "marginBottom": "1.3333333em",
      "paddingInlineStart": "1.5555556em"
    },
    "ul": {
      "marginTop": "1.3333333em",
      "marginBottom": "1.3333333em",
      "paddingInlineStart": "1.5555556em"
    },
    "li": {
      "marginTop": "0.6666667em",
      "marginBottom": "0.6666667em"
    },
    "ol > li": {
      "paddingInlineStart": "0.4444444em"
    },
    "ul > li": {
      "paddingInlineStart": "0.4444444em"
    },
    "> ul > li p": {
      "marginTop": "0.8888889em",
      "marginBottom": "0.8888889em"
    },
    "> ul > li > p:first-child": {
      "marginTop": "1.3333333em"
    },
    "> ul > li > p:last-child": {
      "marginBottom": "1.3333333em"
    },
    "> ol > li > p:first-child": {
      "marginTop": "1.3333333em"
    },
    "> ol > li > p:last-child": {
      "marginBottom": "1.3333333em"
    },
    "ul ul, ul ol, ol ul, ol ol": {
      "marginTop": "0.8888889em",
      "marginBottom": "0.8888889em"
    },
    "dl": {
      "marginTop": "1.3333333em",
      "marginBottom": "1.3333333em"
    },
    "dt": {
      "marginTop": "1.3333333em"
    },
    "dd": {
      "marginTop": "0.6666667em",
      "paddingInlineStart": "1.5555556em"
    },
    "hr": {
      "marginTop": "3.1111111em",
      "marginBottom": "3.1111111em"
    },
    "hr + *": {
      "marginTop": "0"
    },
    "h2 + *": {
      "marginTop": "0"
    },
    "h3 + *": {
      "marginTop": "0"
    },
    "h4 + *": {
      "marginTop": "0"
    },
    "table": {
      "fontSize": "0.8888889em",
      "lineHeight": "1.5"
    },
    "thead th": {
      "paddingInlineEnd": "0.75em",
      "paddingBottom": "0.75em",
      "paddingInlineStart": "0.75em"
    },
    "thead th:first-child": {
      "paddingInlineStart": "0"
    },
    "thead th:last-child": {
      "paddingInlineEnd": "0"
    },
    "tbody td, tfoot td": {
      "paddingTop": "0.75em",
      "paddingInlineEnd": "0.75em",
      "paddingBottom": "0.75em",
      "paddingInlineStart": "0.75em"
    },
    "tbody td:first-child, tfoot td:first-child": {
      "paddingInlineStart": "0"
    },
    "tbody td:last-child, tfoot td:last-child": {
      "paddingInlineEnd": "0"
    },
    "figure": {
      "marginTop": "1.7777778em",
      "marginBottom": "1.7777778em"
    },
    "figure > *": {
      "marginTop": "0",
      "marginBottom": "0"
    },
    "figcaption": {
      "fontSize": "0.8888889em",
      "lineHeight": "1.5",
      "marginTop": "1em"
    }
  },
  {
    "> :first-child": {
      "marginTop": "0"
    },
    "> :last-child": {
      "marginBottom": "0"
    }
  },
  {
    "fontSize": "1.125rem",
    "lineHeight": "1.7777777777777777/* 32px */",
    "h1": {
      "fontSize": "3.75rem"
    },
    "h2": {
      "fontSize": "3rem",
      "marginTop": 0,
      "marginBottom": "2rem"
    },
    "h3": {
      "fontSize": "2.25rem",
      "marginTop": 0,
      "marginBottom": "2rem"
    },
    "h4": {
      "fontSize": "1.875rem",
      "marginTop": 0,
      "marginBottom": "2rem"
    },
    "h5": {
      "fontSize": "1.5rem",
      "marginTop": 0,
      "marginBottom": "2rem"
    },
    "h6": {
      "fontSize": "1.125rem",
      "marginTop": 0,
      "marginBottom": "2rem"
    },
    "p": {
      "marginTop": 0,
      "marginBottom": "2rem"
    },
    "hr": {
      "marginBottom": "2rem",
      "marginTop": 0
    }
  }
]