import _none from "./borderRadius/none.mjs"
import _sm from "./borderRadius/sm.mjs"
import _DEFAULT from "./borderRadius/DEFAULT.mjs"
import _md from "./borderRadius/md.mjs"
import _lg from "./borderRadius/lg.mjs"
import _xl from "./borderRadius/xl.mjs"
import _2xl from "./borderRadius/2xl.mjs"
import _3xl from "./borderRadius/3xl.mjs"
import _full from "./borderRadius/full.mjs"
const config = { "none": _none, "sm": _sm, "DEFAULT": _DEFAULT, "md": _md, "lg": _lg, "xl": _xl, "2xl": _2xl, "3xl": _3xl, "full": _full }
export { config as default, _none, _sm, _DEFAULT, _md, _lg, _xl, _2xl, _3xl, _full }