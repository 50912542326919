import _1 from "./gridColumnEnd/1.mjs"
import _2 from "./gridColumnEnd/2.mjs"
import _3 from "./gridColumnEnd/3.mjs"
import _4 from "./gridColumnEnd/4.mjs"
import _5 from "./gridColumnEnd/5.mjs"
import _6 from "./gridColumnEnd/6.mjs"
import _7 from "./gridColumnEnd/7.mjs"
import _8 from "./gridColumnEnd/8.mjs"
import _9 from "./gridColumnEnd/9.mjs"
import _10 from "./gridColumnEnd/10.mjs"
import _11 from "./gridColumnEnd/11.mjs"
import _12 from "./gridColumnEnd/12.mjs"
import _13 from "./gridColumnEnd/13.mjs"
import _auto from "./gridColumnEnd/auto.mjs"
const config = { "1": _1, "2": _2, "3": _3, "4": _4, "5": _5, "6": _6, "7": _7, "8": _8, "9": _9, "10": _10, "11": _11, "12": _12, "13": _13, "auto": _auto }
export { config as default, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _auto }