import _0 from "./transitionDuration/0.mjs"
import _75 from "./transitionDuration/75.mjs"
import _100 from "./transitionDuration/100.mjs"
import _150 from "./transitionDuration/150.mjs"
import _200 from "./transitionDuration/200.mjs"
import _300 from "./transitionDuration/300.mjs"
import _500 from "./transitionDuration/500.mjs"
import _700 from "./transitionDuration/700.mjs"
import _1000 from "./transitionDuration/1000.mjs"
import _DEFAULT from "./transitionDuration/DEFAULT.mjs"
const config = { "0": _0, "75": _75, "100": _100, "150": _150, "200": _200, "300": _300, "500": _500, "700": _700, "1000": _1000, "DEFAULT": _DEFAULT }
export { config as default, _0, _75, _100, _150, _200, _300, _500, _700, _1000, _DEFAULT }