export default [
  "1.125rem",
  {
    "lineHeight": "1.75rem"
  },
  "1.125rem",
  {
    "lineHeight": "1.75rem"
  },
  "1.125rem",
  {
    "lineHeight": "1.75rem"
  },
  "1.125rem",
  {
    "lineHeight": "1.75rem"
  }
]