import _xs from "./fontSize/xs.mjs"
import _sm from "./fontSize/sm.mjs"
import _base from "./fontSize/base.mjs"
import _lg from "./fontSize/lg.mjs"
import _xl from "./fontSize/xl.mjs"
import _2xl from "./fontSize/2xl.mjs"
import _3xl from "./fontSize/3xl.mjs"
import _4xl from "./fontSize/4xl.mjs"
import _5xl from "./fontSize/5xl.mjs"
import _6xl from "./fontSize/6xl.mjs"
import _7xl from "./fontSize/7xl.mjs"
import _8xl from "./fontSize/8xl.mjs"
import _9xl from "./fontSize/9xl.mjs"
const config = { "xs": _xs, "sm": _sm, "base": _base, "lg": _lg, "xl": _xl, "2xl": _2xl, "3xl": _3xl, "4xl": _4xl, "5xl": _5xl, "6xl": _6xl, "7xl": _7xl, "8xl": _8xl, "9xl": _9xl }
export { config as default, _xs, _sm, _base, _lg, _xl, _2xl, _3xl, _4xl, _5xl, _6xl, _7xl, _8xl, _9xl }