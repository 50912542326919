export default [
  "2.25rem",
  {
    "lineHeight": "2.5rem",
    "fontWeight": "500"
  },
  "2.25rem",
  {
    "lineHeight": "2.5rem",
    "fontWeight": "500"
  },
  "2.25rem",
  {
    "lineHeight": "2.5rem",
    "fontWeight": "500"
  },
  "2.25rem",
  {
    "lineHeight": "2.5rem",
    "fontWeight": "500"
  }
]