import _1 from "./aspectRatio/1.mjs"
import _2 from "./aspectRatio/2.mjs"
import _3 from "./aspectRatio/3.mjs"
import _4 from "./aspectRatio/4.mjs"
import _5 from "./aspectRatio/5.mjs"
import _6 from "./aspectRatio/6.mjs"
import _7 from "./aspectRatio/7.mjs"
import _8 from "./aspectRatio/8.mjs"
import _9 from "./aspectRatio/9.mjs"
import _10 from "./aspectRatio/10.mjs"
import _11 from "./aspectRatio/11.mjs"
import _12 from "./aspectRatio/12.mjs"
import _13 from "./aspectRatio/13.mjs"
import _14 from "./aspectRatio/14.mjs"
import _15 from "./aspectRatio/15.mjs"
import _16 from "./aspectRatio/16.mjs"
const config = { "1": _1, "2": _2, "3": _3, "4": _4, "5": _5, "6": _6, "7": _7, "8": _8, "9": _9, "10": _10, "11": _11, "12": _12, "13": _13, "14": _14, "15": _15, "16": _16 }
export { config as default, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16 }