<template>
  <nav class="w-fit lg:mx-auto lg:mr-0">
    <NuxtLink
      v-for="item in data"
      :key="item.id"
      :to="
        item.reference
          ? (localePathByType(
              item!.reference._modelApiKey,
              item.reference.slug as string
            ) as string)
          : (item.externalLink as string)
      "
      :target="item.externalLink ? '_blank' : undefined"
      class="block w-fit text-sm underline lg:m-0 lg:ml-6 lg:inline-block"
      noPrefetch
    >
      {{ item.title }}
    </NuxtLink>
  </nav>
</template>

<script setup lang="ts">
import type { MenuFooterRecord } from '~/types/datocms'

defineProps({
  data: {
    type: Array as PropType<MenuFooterRecord[]>
  }
})

const { localePathByType } = useLocalePathByType()
</script>
