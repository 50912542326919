export default [
  {
    "fontSize": "1.25rem",
    "lineHeight": "1.8",
    "p": {
      "marginTop": "1.2em",
      "marginBottom": "1.2em"
    },
    "[class~=\"lead\"]": {
      "fontSize": "1.2em",
      "lineHeight": "1.5",
      "marginTop": "1em",
      "marginBottom": "1em"
    },
    "blockquote": {
      "marginTop": "1.6em",
      "marginBottom": "1.6em",
      "paddingInlineStart": "1.0666667em"
    },
    "h1": {
      "fontSize": "2.8em",
      "marginTop": "0",
      "marginBottom": "0.8571429em",
      "lineHeight": "1"
    },
    "h2": {
      "fontSize": "1.8em",
      "marginTop": "1.5555556em",
      "marginBottom": "0.8888889em",
      "lineHeight": "1.1111111"
    },
    "h3": {
      "fontSize": "1.5em",
      "marginTop": "1.6em",
      "marginBottom": "0.6666667em",
      "lineHeight": "1.3333333"
    },
    "h4": {
      "marginTop": "1.8em",
      "marginBottom": "0.6em",
      "lineHeight": "1.6"
    },
    "img": {
      "marginTop": "2em",
      "marginBottom": "2em"
    },
    "picture": {
      "marginTop": "2em",
      "marginBottom": "2em"
    },
    "picture > img": {
      "marginTop": "0",
      "marginBottom": "0"
    },
    "video": {
      "marginTop": "2em",
      "marginBottom": "2em"
    },
    "kbd": {
      "fontSize": "0.9em",
      "borderRadius": "0.3125rem",
      "paddingTop": "0.25em",
      "paddingInlineEnd": "0.4em",
      "paddingBottom": "0.25em",
      "paddingInlineStart": "0.4em"
    },
    "code": {
      "fontSize": "0.9em"
    },
    "h2 code": {
      "fontSize": "0.8611111em"
    },
    "h3 code": {
      "fontSize": "0.9em"
    },
    "pre": {
      "fontSize": "0.9em",
      "lineHeight": "1.7777778",
      "marginTop": "2em",
      "marginBottom": "2em",
      "borderRadius": "0.5rem",
      "paddingTop": "1.1111111em",
      "paddingInlineEnd": "1.3333333em",
      "paddingBottom": "1.1111111em",
      "paddingInlineStart": "1.3333333em"
    },
    "ol": {
      "marginTop": "1.2em",
      "marginBottom": "1.2em",
      "paddingInlineStart": "1.6em"
    },
    "ul": {
      "marginTop": "1.2em",
      "marginBottom": "1.2em",
      "paddingInlineStart": "1.6em"
    },
    "li": {
      "marginTop": "0.6em",
      "marginBottom": "0.6em"
    },
    "ol > li": {
      "paddingInlineStart": "0.4em"
    },
    "ul > li": {
      "paddingInlineStart": "0.4em"
    },
    "> ul > li p": {
      "marginTop": "0.8em",
      "marginBottom": "0.8em"
    },
    "> ul > li > p:first-child": {
      "marginTop": "1.2em"
    },
    "> ul > li > p:last-child": {
      "marginBottom": "1.2em"
    },
    "> ol > li > p:first-child": {
      "marginTop": "1.2em"
    },
    "> ol > li > p:last-child": {
      "marginBottom": "1.2em"
    },
    "ul ul, ul ol, ol ul, ol ol": {
      "marginTop": "0.8em",
      "marginBottom": "0.8em"
    },
    "dl": {
      "marginTop": "1.2em",
      "marginBottom": "1.2em"
    },
    "dt": {
      "marginTop": "1.2em"
    },
    "dd": {
      "marginTop": "0.6em",
      "paddingInlineStart": "1.6em"
    },
    "hr": {
      "marginTop": "2.8em",
      "marginBottom": "2.8em"
    },
    "hr + *": {
      "marginTop": "0"
    },
    "h2 + *": {
      "marginTop": "0"
    },
    "h3 + *": {
      "marginTop": "0"
    },
    "h4 + *": {
      "marginTop": "0"
    },
    "table": {
      "fontSize": "0.9em",
      "lineHeight": "1.5555556"
    },
    "thead th": {
      "paddingInlineEnd": "0.6666667em",
      "paddingBottom": "0.8888889em",
      "paddingInlineStart": "0.6666667em"
    },
    "thead th:first-child": {
      "paddingInlineStart": "0"
    },
    "thead th:last-child": {
      "paddingInlineEnd": "0"
    },
    "tbody td, tfoot td": {
      "paddingTop": "0.8888889em",
      "paddingInlineEnd": "0.6666667em",
      "paddingBottom": "0.8888889em",
      "paddingInlineStart": "0.6666667em"
    },
    "tbody td:first-child, tfoot td:first-child": {
      "paddingInlineStart": "0"
    },
    "tbody td:last-child, tfoot td:last-child": {
      "paddingInlineEnd": "0"
    },
    "figure": {
      "marginTop": "2em",
      "marginBottom": "2em"
    },
    "figure > *": {
      "marginTop": "0",
      "marginBottom": "0"
    },
    "figcaption": {
      "fontSize": "0.9em",
      "lineHeight": "1.5555556",
      "marginTop": "1em"
    }
  },
  {
    "> :first-child": {
      "marginTop": "0"
    },
    "> :last-child": {
      "marginBottom": "0"
    }
  }
]