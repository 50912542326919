import _3 from "./lineHeight/3.mjs"
import _4 from "./lineHeight/4.mjs"
import _5 from "./lineHeight/5.mjs"
import _6 from "./lineHeight/6.mjs"
import _7 from "./lineHeight/7.mjs"
import _8 from "./lineHeight/8.mjs"
import _9 from "./lineHeight/9.mjs"
import _10 from "./lineHeight/10.mjs"
import _none from "./lineHeight/none.mjs"
import _tight from "./lineHeight/tight.mjs"
import _snug from "./lineHeight/snug.mjs"
import _normal from "./lineHeight/normal.mjs"
import _relaxed from "./lineHeight/relaxed.mjs"
import _loose from "./lineHeight/loose.mjs"
import _tighter from "./lineHeight/tighter.mjs"
const config = { "3": _3, "4": _4, "5": _5, "6": _6, "7": _7, "8": _8, "9": _9, "10": _10, "none": _none, "tight": _tight, "snug": _snug, "normal": _normal, "relaxed": _relaxed, "loose": _loose, "tighter": _tighter }
export { config as default, _3, _4, _5, _6, _7, _8, _9, _10, _none, _tight, _snug, _normal, _relaxed, _loose, _tighter }