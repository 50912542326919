export default [
  "Outfit",
  "ui-sans-serif",
  "system-ui",
  "Outfit",
  "ui-sans-serif",
  "system-ui",
  "Outfit",
  "ui-sans-serif",
  "system-ui",
  "Outfit",
  "ui-sans-serif",
  "system-ui"
]