export default [
  "0.75rem",
  {
    "lineHeight": "1rem"
  },
  "0.75rem",
  {
    "lineHeight": "1rem"
  },
  "0.75rem",
  {
    "lineHeight": "1rem"
  },
  "0.75rem",
  {
    "lineHeight": "1rem"
  }
]