import _1 from "./gridTemplateRows/1.mjs"
import _2 from "./gridTemplateRows/2.mjs"
import _3 from "./gridTemplateRows/3.mjs"
import _4 from "./gridTemplateRows/4.mjs"
import _5 from "./gridTemplateRows/5.mjs"
import _6 from "./gridTemplateRows/6.mjs"
import _7 from "./gridTemplateRows/7.mjs"
import _8 from "./gridTemplateRows/8.mjs"
import _9 from "./gridTemplateRows/9.mjs"
import _10 from "./gridTemplateRows/10.mjs"
import _11 from "./gridTemplateRows/11.mjs"
import _12 from "./gridTemplateRows/12.mjs"
import _none from "./gridTemplateRows/none.mjs"
import _subgrid from "./gridTemplateRows/subgrid.mjs"
const config = { "1": _1, "2": _2, "3": _3, "4": _4, "5": _5, "6": _6, "7": _7, "8": _8, "9": _9, "10": _10, "11": _11, "12": _12, "none": _none, "subgrid": _subgrid }
export { config as default, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _none, _subgrid }