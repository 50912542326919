import _0 from "./blur/0.mjs"
import _none from "./blur/none.mjs"
import _sm from "./blur/sm.mjs"
import _DEFAULT from "./blur/DEFAULT.mjs"
import _md from "./blur/md.mjs"
import _lg from "./blur/lg.mjs"
import _xl from "./blur/xl.mjs"
import _2xl from "./blur/2xl.mjs"
import _3xl from "./blur/3xl.mjs"
const config = { "0": _0, "none": _none, "sm": _sm, "DEFAULT": _DEFAULT, "md": _md, "lg": _lg, "xl": _xl, "2xl": _2xl, "3xl": _3xl }
export { config as default, _0, _none, _sm, _DEFAULT, _md, _lg, _xl, _2xl, _3xl }