import _transparent from "./placeholderColor/transparent.mjs"
import _current from "./placeholderColor/current.mjs"
import _gray from "./placeholderColor/gray.mjs"
import _black from "./placeholderColor/black.mjs"
import _white from "./placeholderColor/white.mjs"
import _neutral from "./placeholderColor/neutral.mjs"
import _yellow from "./placeholderColor/yellow.mjs"
import _blue from "./placeholderColor/blue.mjs"
import _green from "./placeholderColor/green.mjs"
import _red from "./placeholderColor/red.mjs"
import _primary from "./placeholderColor/primary.mjs"
import _secondary from "./placeholderColor/secondary.mjs"
import _tertiary from "./placeholderColor/tertiary.mjs"
import _disabled from "./placeholderColor/disabled.mjs"
import _dark from "./placeholderColor/dark.mjs"
const config = { "transparent": _transparent, "current": _current, "gray": _gray, "black": _black, "white": _white, "neutral": _neutral, "yellow": _yellow, "blue": _blue, "green": _green, "red": _red, "primary": _primary, "secondary": _secondary, "tertiary": _tertiary, "disabled": _disabled, "dark": _dark }
export { config as default, _transparent, _current, _gray, _black, _white, _neutral, _yellow, _blue, _green, _red, _primary, _secondary, _tertiary, _disabled, _dark }