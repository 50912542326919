import _50 from "./neutral/50.mjs"
import _100 from "./neutral/100.mjs"
import _200 from "./neutral/200.mjs"
import _300 from "./neutral/300.mjs"
import _400 from "./neutral/400.mjs"
import _500 from "./neutral/500.mjs"
import _600 from "./neutral/600.mjs"
import _700 from "./neutral/700.mjs"
import _800 from "./neutral/800.mjs"
import _900 from "./neutral/900.mjs"
import _950 from "./neutral/950.mjs"
const config = { "50": _50, "100": _100, "200": _200, "300": _300, "400": _400, "500": _500, "600": _600, "700": _700, "800": _800, "900": _900, "950": _950 }
export { config as default, _50, _100, _200, _300, _400, _500, _600, _700, _800, _900, _950 }