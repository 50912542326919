import _1 from "./columns/1.mjs"
import _2 from "./columns/2.mjs"
import _3 from "./columns/3.mjs"
import _4 from "./columns/4.mjs"
import _5 from "./columns/5.mjs"
import _6 from "./columns/6.mjs"
import _7 from "./columns/7.mjs"
import _8 from "./columns/8.mjs"
import _9 from "./columns/9.mjs"
import _10 from "./columns/10.mjs"
import _11 from "./columns/11.mjs"
import _12 from "./columns/12.mjs"
import _auto from "./columns/auto.mjs"
import _3xs from "./columns/3xs.mjs"
import _2xs from "./columns/2xs.mjs"
import _xs from "./columns/xs.mjs"
import _sm from "./columns/sm.mjs"
import _md from "./columns/md.mjs"
import _lg from "./columns/lg.mjs"
import _xl from "./columns/xl.mjs"
import _2xl from "./columns/2xl.mjs"
import _3xl from "./columns/3xl.mjs"
import _4xl from "./columns/4xl.mjs"
import _5xl from "./columns/5xl.mjs"
import _6xl from "./columns/6xl.mjs"
import _7xl from "./columns/7xl.mjs"
const config = { "1": _1, "2": _2, "3": _3, "4": _4, "5": _5, "6": _6, "7": _7, "8": _8, "9": _9, "10": _10, "11": _11, "12": _12, "auto": _auto, "3xs": _3xs, "2xs": _2xs, "xs": _xs, "sm": _sm, "md": _md, "lg": _lg, "xl": _xl, "2xl": _2xl, "3xl": _3xl, "4xl": _4xl, "5xl": _5xl, "6xl": _6xl, "7xl": _7xl }
export { config as default, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _auto, _3xs, _2xs, _xs, _sm, _md, _lg, _xl, _2xl, _3xl, _4xl, _5xl, _6xl, _7xl }