import _1 from "./order/1.mjs"
import _2 from "./order/2.mjs"
import _3 from "./order/3.mjs"
import _4 from "./order/4.mjs"
import _5 from "./order/5.mjs"
import _6 from "./order/6.mjs"
import _7 from "./order/7.mjs"
import _8 from "./order/8.mjs"
import _9 from "./order/9.mjs"
import _10 from "./order/10.mjs"
import _11 from "./order/11.mjs"
import _12 from "./order/12.mjs"
import _first from "./order/first.mjs"
import _last from "./order/last.mjs"
import _none from "./order/none.mjs"
const config = { "1": _1, "2": _2, "3": _3, "4": _4, "5": _5, "6": _6, "7": _7, "8": _8, "9": _9, "10": _10, "11": _11, "12": _12, "first": _first, "last": _last, "none": _none }
export { config as default, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _first, _last, _none }