<template>
  <footer class="bg-white pb-9 pt-12 text-black lg:pb-20 lg:pt-32">
    <div class="md :gap-x-8 container grid grid-cols-6 gap-x-4 md:gap-y-14 lg:grid-cols-12">
      <div class="col-span-6">
        <Logo class="h-14 w-60 lg:h-16 lg:w-76" variant="mobile" />
      </div>
      <div class="order-3 col-span-6 md:order-2">
        <Social />
      </div>

      <div class="order-2 col-span-6 md:order-3 md:col-span-12">
        <SiteMap :data="data.allMenus" />
      </div>
    </div>

    <hr class="mx-4 mb-7 mt-14 text-neutral-300 lg:mx-0 lg:mt-16" />

    <div class="container grid grid-cols-12">
      <div class="col-span-6 lg:flex lg:items-center">
        <small class="block whitespace-nowrap text-sm">@ {{ year }} Štajerska</small>
      </div>
      <div class="col-span-6">
        <FooterNav :data="data.allMenuFooters" />
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { AppState } from '~/stores/app'

defineProps({
  data: {
    type: Object as PropType<AppState>,
    default: () => {}
  }
})

const year = new Date().getFullYear()
</script>
