import _0 from "./brightness/0.mjs"
import _50 from "./brightness/50.mjs"
import _75 from "./brightness/75.mjs"
import _90 from "./brightness/90.mjs"
import _95 from "./brightness/95.mjs"
import _100 from "./brightness/100.mjs"
import _105 from "./brightness/105.mjs"
import _110 from "./brightness/110.mjs"
import _125 from "./brightness/125.mjs"
import _150 from "./brightness/150.mjs"
import _200 from "./brightness/200.mjs"
const config = { "0": _0, "50": _50, "75": _75, "90": _90, "95": _95, "100": _100, "105": _105, "110": _110, "125": _125, "150": _150, "200": _200 }
export { config as default, _0, _50, _75, _90, _95, _100, _105, _110, _125, _150, _200 }