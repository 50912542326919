export default [
  "4.5rem",
  {
    "lineHeight": "5.5rem",
    "fontWeight": "400"
  },
  "4.5rem",
  {
    "lineHeight": "5.5rem",
    "fontWeight": "400"
  },
  "4.5rem",
  {
    "lineHeight": "5.5rem",
    "fontWeight": "400"
  },
  "4.5rem",
  {
    "lineHeight": "5.5rem",
    "fontWeight": "400"
  }
]