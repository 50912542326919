export default [
  "8rem",
  {
    "lineHeight": "1"
  },
  "8rem",
  {
    "lineHeight": "1"
  },
  "8rem",
  {
    "lineHeight": "1"
  },
  "8rem",
  {
    "lineHeight": "1"
  }
]