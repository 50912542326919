export default [
  "1rem",
  {
    "lineHeight": "1.5rem"
  },
  "1rem",
  {
    "lineHeight": "1.5rem"
  },
  "1rem",
  {
    "lineHeight": "1.5rem"
  },
  "1rem",
  {
    "lineHeight": "1.5rem"
  }
]