import _transparent from "./boxShadowColor/transparent.mjs"
import _current from "./boxShadowColor/current.mjs"
import _gray from "./boxShadowColor/gray.mjs"
import _black from "./boxShadowColor/black.mjs"
import _white from "./boxShadowColor/white.mjs"
import _neutral from "./boxShadowColor/neutral.mjs"
import _yellow from "./boxShadowColor/yellow.mjs"
import _blue from "./boxShadowColor/blue.mjs"
import _green from "./boxShadowColor/green.mjs"
import _red from "./boxShadowColor/red.mjs"
import _primary from "./boxShadowColor/primary.mjs"
import _secondary from "./boxShadowColor/secondary.mjs"
import _tertiary from "./boxShadowColor/tertiary.mjs"
import _disabled from "./boxShadowColor/disabled.mjs"
import _dark from "./boxShadowColor/dark.mjs"
const config = { "transparent": _transparent, "current": _current, "gray": _gray, "black": _black, "white": _white, "neutral": _neutral, "yellow": _yellow, "blue": _blue, "green": _green, "red": _red, "primary": _primary, "secondary": _secondary, "tertiary": _tertiary, "disabled": _disabled, "dark": _dark }
export { config as default, _transparent, _current, _gray, _black, _white, _neutral, _yellow, _blue, _green, _red, _primary, _secondary, _tertiary, _disabled, _dark }