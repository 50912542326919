import _none from "./transitionProperty/none.mjs"
import _all from "./transitionProperty/all.mjs"
import _DEFAULT from "./transitionProperty/DEFAULT.mjs"
import _colors from "./transitionProperty/colors.mjs"
import _opacity from "./transitionProperty/opacity.mjs"
import _shadow from "./transitionProperty/shadow.mjs"
import _transform from "./transitionProperty/transform.mjs"
import _height from "./transitionProperty/height.mjs"
import _width from "./transitionProperty/width.mjs"
const config = { "none": _none, "all": _all, "DEFAULT": _DEFAULT, "colors": _colors, "opacity": _opacity, "shadow": _shadow, "transform": _transform, "height": _height, "width": _width }
export { config as default, _none, _all, _DEFAULT, _colors, _opacity, _shadow, _transform, _height, _width }