import _none from "./fill/none.mjs"
import _transparent from "./fill/transparent.mjs"
import _current from "./fill/current.mjs"
import _gray from "./fill/gray.mjs"
import _black from "./fill/black.mjs"
import _white from "./fill/white.mjs"
import _neutral from "./fill/neutral.mjs"
import _yellow from "./fill/yellow.mjs"
import _blue from "./fill/blue.mjs"
import _green from "./fill/green.mjs"
import _red from "./fill/red.mjs"
import _primary from "./fill/primary.mjs"
import _secondary from "./fill/secondary.mjs"
import _tertiary from "./fill/tertiary.mjs"
import _disabled from "./fill/disabled.mjs"
import _dark from "./fill/dark.mjs"
const config = { "none": _none, "transparent": _transparent, "current": _current, "gray": _gray, "black": _black, "white": _white, "neutral": _neutral, "yellow": _yellow, "blue": _blue, "green": _green, "red": _red, "primary": _primary, "secondary": _secondary, "tertiary": _tertiary, "disabled": _disabled, "dark": _dark }
export { config as default, _none, _transparent, _current, _gray, _black, _white, _neutral, _yellow, _blue, _green, _red, _primary, _secondary, _tertiary, _disabled, _dark }