import _0 from "./opacity/0.mjs"
import _5 from "./opacity/5.mjs"
import _10 from "./opacity/10.mjs"
import _15 from "./opacity/15.mjs"
import _20 from "./opacity/20.mjs"
import _25 from "./opacity/25.mjs"
import _30 from "./opacity/30.mjs"
import _35 from "./opacity/35.mjs"
import _40 from "./opacity/40.mjs"
import _45 from "./opacity/45.mjs"
import _50 from "./opacity/50.mjs"
import _55 from "./opacity/55.mjs"
import _60 from "./opacity/60.mjs"
import _65 from "./opacity/65.mjs"
import _70 from "./opacity/70.mjs"
import _75 from "./opacity/75.mjs"
import _80 from "./opacity/80.mjs"
import _85 from "./opacity/85.mjs"
import _90 from "./opacity/90.mjs"
import _95 from "./opacity/95.mjs"
import _100 from "./opacity/100.mjs"
const config = { "0": _0, "5": _5, "10": _10, "15": _15, "20": _20, "25": _25, "30": _30, "35": _35, "40": _40, "45": _45, "50": _50, "55": _55, "60": _60, "65": _65, "70": _70, "75": _75, "80": _80, "85": _85, "90": _90, "95": _95, "100": _100 }
export { config as default, _0, _5, _10, _15, _20, _25, _30, _35, _40, _45, _50, _55, _60, _65, _70, _75, _80, _85, _90, _95, _100 }