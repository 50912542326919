import _0 from "./backgroundOpacity/0.mjs"
import _5 from "./backgroundOpacity/5.mjs"
import _10 from "./backgroundOpacity/10.mjs"
import _15 from "./backgroundOpacity/15.mjs"
import _20 from "./backgroundOpacity/20.mjs"
import _25 from "./backgroundOpacity/25.mjs"
import _30 from "./backgroundOpacity/30.mjs"
import _35 from "./backgroundOpacity/35.mjs"
import _40 from "./backgroundOpacity/40.mjs"
import _45 from "./backgroundOpacity/45.mjs"
import _50 from "./backgroundOpacity/50.mjs"
import _55 from "./backgroundOpacity/55.mjs"
import _60 from "./backgroundOpacity/60.mjs"
import _65 from "./backgroundOpacity/65.mjs"
import _70 from "./backgroundOpacity/70.mjs"
import _75 from "./backgroundOpacity/75.mjs"
import _80 from "./backgroundOpacity/80.mjs"
import _85 from "./backgroundOpacity/85.mjs"
import _90 from "./backgroundOpacity/90.mjs"
import _95 from "./backgroundOpacity/95.mjs"
import _100 from "./backgroundOpacity/100.mjs"
const config = { "0": _0, "5": _5, "10": _10, "15": _15, "20": _20, "25": _25, "30": _30, "35": _35, "40": _40, "45": _45, "50": _50, "55": _55, "60": _60, "65": _65, "70": _70, "75": _75, "80": _80, "85": _85, "90": _90, "95": _95, "100": _100 }
export { config as default, _0, _5, _10, _15, _20, _25, _30, _35, _40, _45, _50, _55, _60, _65, _70, _75, _80, _85, _90, _95, _100 }