<template>
  <div class="flex w-[fit-content] gap-10 lg:ml-auto">
    <a
      v-for="item in links"
      :key="item.name"
      target="_blank"
      rel="noopener noreferrer"
      :href="item.href"
      :title="item.title"
      class="inline-block"
      ><Icon :name="item.icon" color="black" size="lg"
    /></a>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()

const links = [
  {
    name: 'Facebook',
    href: 'https://www.facebook.com/stajerska.si/',
    icon: 'facebook',
    title: t('social.links.facebook')
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/stajerska.si/',
    icon: 'instagram',
    title: t('social.links.instagram')
  },
  {
    name: 'YouTube',
    href: 'https://www.youtube.com/@Stajerska',
    icon: 'youtube',
    title: t('social.links.youtube')
  }
]
</script>
