<template>
  <div v-click-outside="hideDropdown" class="relative mr-12 lg:mr-0">
    <button
      id="dropdownButton"
      class="inline-flex h-8 w-8 items-center justify-center gap-3 uppercase outline-none lg:h-auto lg:w-auto lg:rounded-[80px] lg:border lg:p-3"
      :class="[
        isMobileMenuVisible ? 'block' : 'hidden lg:inline-flex',
        isWhite ? 'text-white lg:border-white' : 'text-neutral-900 lg:border-neutral-900'
      ]"
      @click="isActive = !isActive"
    >
      <Icon class="lg:hidden" name="globe" color="white" size="sm" />
      <span class="hidden lg:inline">{{ activeLocale }}</span>
      <Icon
        class="hidden transition-transform duration-500 lg:block"
        name="chevron-down"
        size="xs"
        :color="isActive || !isWhite ? 'red' : 'inherit'"
        :class="{ 'rotate-180': isActive }"
      />
    </button>

    <Transition name="fade">
      <ul
        v-if="isActive"
        class="absolute right-0 mt-3 bg-white px-5 pb-6 pt-5 shadow-lg"
        aria-labelledby="dropdownButton"
      >
        <template v-for="item in availableLocales" :key="item.code">
          <li v-if="item.code !== 'de'" class="mt-3 first:mt-0">
            <NuxtLink
              v-if="item.code !== 'de'"
              :to="item.code === defaultLocale ? '/' : `/${item.code}`"
              :hreflang="item.code"
              class="text-base text-neutral-900"
              @click="switchLanguage(item.code)"
            >
              {{ item.name }}
            </NuxtLink>
          </li>
        </template>
      </ul>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { LocaleObject } from '@nuxtjs/i18n/dist/runtime/composables'

defineProps({
  isWhite: {
    type: Boolean,
    default: false
  }
})

const { locale, locales, defaultLocale } = useI18n()
const isActive = ref(false)
const isMobileMenuVisible = useState('menu')
const availableLocales = computed(() =>
  locales.value.filter((i: LocaleObject) => i.code !== locale.value)
)
const activeLocale = computed(() =>
  locales.value.find((l: LocaleObject) => l.code === locale.value).name.substring(0, 3)
)

function hideDropdown() {
  isActive.value = false
}

const setNetlifyCookie = (code: string) => {
  const country = useCookie('nf_country')
  country.value = code
}

const switchLanguage = (lang: string) => {
  setNetlifyCookie(lang)
  isActive.value = false
}
</script>
